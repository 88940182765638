/**
 * Created by BETALOS on 22/06/2017.
 */
(function () {

    'use strict';

    const COLOR_PICKER_PARAMS = require('parameters/json/color-picker-config.json');

    class ReasonDialogCtrl {
        constructor($mdDialog, $translate, frontDeskService, mnTheme) {
            this.dialog = $mdDialog;
            this.mnTheme = mnTheme;
            this.frontDeskService = frontDeskService;

            this.options = _.assign({}, COLOR_PICKER_PARAMS, {label: $translate.instant('reason_dialog_color')});
            this.options_2 = _.assign({}, COLOR_PICKER_PARAMS, {label: $translate.instant('reason_dialog_striped_color')});
        }

        static get $inject() {
            return ["$mdDialog", "$translate", "frontDeskService", "mnTheme"];
        }

        $onInit() {
            this.model = _.isNil(this.model) ? {appointment_duration: 15} : this.model;
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.frontDeskService.handleReason(this.model)
                .then(data => {
                    this.mnTheme.refreshVisitReasons();
                    this.dialog.hide(data);
                }, _.noop);
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: ReasonDialogCtrl,
        template: require("frontdesk/views/reason-dialog.tpl.html"),
    };

})();