(function () {

    'use strict';

    class MultiPaymentModeDialogCtrl {
        constructor($mdDialog, $translate, system) {
            this.system = system;
            this.$mdDialog = $mdDialog;
            this.$translate = $translate;
            this.dateFormat = this.system['date_format'].js;
            this.cancel = $mdDialog.cancel;
            this.init_mode= {ref:'', due_date:moment().format(this.dateFormat)};

        }

        static get $inject() {
            return ["$mdDialog", "$translate", "system"];
        }

        $onInit() {
            this.query = {
                limit: 5,
                page: 1,
                order: "-ref"
            };
            this.options = [5, 10, 20,50,100];
            this.paginationLabel = {
                page: this.$translate.instant('page'),
                rowsPerPage: this.$translate.instant('rowsPerPage'),
                of: this.$translate.instant('of')
            };
            this.readOnly=this.readOnly||false;
            this.modes=this.modes || [];
            this.payment_mode= _.cloneDeep(this.init_mode);

        }

        onPaginate(page, limit) {
            vm.query = _.assign(vm.query, {page: page, limit: limit});
            if (vm.modes.length < ((vm.query.page - 1) * vm.query.limit)) {
                vm.query.page = 1;
            }
        }

        save() {
            this.$mdDialog.hide(this.modes);
        }
        addMode(){
            this.modes.push(this.payment_mode)
            this.payment_mode= _.cloneDeep(this.init_mode);
        }
        removeMode(idx, ev){
            this.modes.splice(idx, 1)
        }
    }

    module.exports = {
        template: require("../views/multi-payment-mode-dialog.tpl.html"),
        controllerAs: "vm",
        controller: MultiPaymentModeDialogCtrl,
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        multiple: true
    }

})();