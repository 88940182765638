/**
 * Created by amine on 13/05/2018.
 */
(function () {

    'use strict';

    class ExamModelFormSetupCtrl {
        constructor($scope, examService, $state, $transition$) {
            this.$scope = $scope;
            this.$state = $state;
            this.examService = examService;
            this.$transition$ = $transition$;

            this.editorOpts = {};
            this.editorConfigKey = "exam_editor";
            this.editorName = _.uniqueId('exam-editor-');
        }

        static get $inject() {
            return ["$scope", "examService", "$state", "$transition$"];
        }

        $onInit() {
            this.modelId = _.get(this.$transition$.params('to'), 'modelId', null);
            this.template = _.get(this.$transition$.params('to'), 'template', null);
            this.copy = _.get(this.$transition$.params('to'), 'copy', null);

            this.examService.sideNaveSubject.next(true);
            this.examService.selectedTemplate.next(this.template);

            if (_.isNil(this.modelId) && _.isNil(this.copy)) this.newModel();
            else if (_.isNil(this.model) && !_.isNil(this.copy)) this.examService.getExamModel(this.copy).then(data => this.duplicate(data), this.error());
            else this.examService.getExamModel(this.modelId).then(data => this.edit(data), () => this.error());
        }

        newModel(){
            this.model = {
                title: "", content: "", template: {id: this.template}
            }

            this.examService.currentModelSubject.next({id: null, type: null});
            this.examService.selectedTemplate.next(this.template);
        }

        duplicate(data) {
            this.model = {
                title: data.title + " (COPY)", content: data.content, template: data.template
            }

            this.examService.currentModelSubject.next({id: data.id, type: 'duplicate'});
            this.examService.selectedTemplate.next(this.model.template);
        }

        edit(data) {
            this.model = data;

            this.examService.currentModelSubject.next({id: this.model.id, type: "edit"});
            this.examService.selectedTemplate.next(this.model.template);
        }

        error() {
            if (this.visitContext) this.examService.listState();
        }

        saveModel(quit) {
            return this.examService.saveExamModel(this.model)
                .then(data => {
                    if (_.isNil(this.model.id)) {
                        this.model.id = data.id;
                        this.examService.currentModelSubject.next({id: data.id, type: 'edit'});

                        if (!quit) this.examService.formModelState(data.template, data, false);
                    }

                    if (quit) this.examService.templateListState();
                });
        }
    }

    module.exports = ExamModelFormSetupCtrl;

})();
